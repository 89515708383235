.dynamic-form {
  width: 100%;
  margin: 0 auto;
}

.form-field {
  margin-bottom: 24px;
  width: 100%;
}

.form-field label {
  display: block;
  font-size: 16px;
  color: #333;
  margin-bottom: 8px;
}

.form-field input,
.form-field select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
}

.form-field input::placeholder,
.form-field select::placeholder {
  color: #999;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
}

.toggle-field {
  background-color: #f8f9fa;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.toggle-field input[type="checkbox"] {
  width: 40px;
  height: 24px;
  margin-right: 12px;
}

.error-message {
  color: #dc3545;
  font-size: 14px;
  margin-top: 4px;
}

.table-container {
  margin-top: 24px;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f8f9fa;
  font-weight: 500;
}

.add-item-button {
  margin-top: 16px;
  padding: 8px 16px;
  background-color: transparent;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
}

.col {
  box-sizing: border-box;
}

.col-1 {
  width: 8.33%;
}
.col-2 {
  width: 16.66%;
}
.col-3 {
  width: 25%;
}
.col-4 {
  width: 32%;
}
.col-5 {
  width: 41.66%;
}
.col-6 {
  width: 50%;
}
.col-7 {
  width: 58.33%;
}
.col-8 {
  width: 66.66%;
}
.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.33%;
}
.col-11 {
  width: 91.66%;
}
.col-12 {
  width: 100%;
}
